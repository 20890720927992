<template>
  <StaticPage ref="staticPage">
    <el-card class="form_wrapper" v-if="customHandling.includes(this.errorCode)">
      <h2>{{ $t('loginClient.header') }}</h2>
      <div class="main" v-if="errorCode === 407">
        <p v-html="$t('loginClient.existingClientPortalAccess')"></p>
        <router-link :to="'/login'" class="el-button" data-testid="goToLogin">
          {{ $t('common.button.login') }}
        </router-link>
      </div>

      <div class="main" v-if="errorCode === 408">
        <p v-html="$t('loginClient.existingClientPortalAccessMobile')"></p>
        <router-link
          :to="{ name: 'loginByMobile', params: { mobileNumber: mobileNumber, ctCode: ctCode } }"
          class="el-button"
          data-testid="goToLoginByMobile"
        >
          {{ $t('common.button.login') }}
        </router-link>
      </div>

      <div class="main" v-if="errorCode === 409">
        <p v-html="$t('loginClient.registerBlacklist')"></p>
        <a class="el-button btn_primary" :href="`https://${url}`" data-testid="goToHomePage">
          {{ $t('common.button.bkToHm') }}
        </a>
      </div>
    </el-card>
    <div class="form_wrapper_error" v-else>
      <img class="img" src="@/assets/images/register/error.png" alt />
      <div class="main">
        {{ $t('responseMsg.' + errorCode) }}
        <p dir="ltr">
          <u>
            <a :href="`https://${url}`">Click here</a>
          </u>
          to go back to the {{ $platform.info.fullName }} homepage, <br />or
          <a :href="'mailto:' + email">
            <u>send us an email</u>
          </a>
          to let us know you've encountered an error.
        </p>
      </div>
      error code : {{ errorCode }}
    </div>
  </StaticPage>
</template>

<script>
import StaticPage from '@/components/template/staticPage/StaticPageDefault';

export default {
  name: 'Error',
  components: { StaticPage },
  data() {
    return {
      url: '',
      email: '',
      mobileNumber: '',
      ctCode: '',
      errorCode: 0,
      customHandling: [407, 408, 409]
    };
  },
  mounted() {
    const regulator = this.$platform.defaultRegulator;
    this.url = this.$platform.info.getUrl(regulator);
    this.email = this.$platform.info.getEmail(regulator);
    this.mobileNumber = this.$route.query.mobile;
    this.ctCode = this.$route.query.countryPhoneCode;
    this.errorCode = parseInt(this.$route.query.code);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/register/error.scss';
</style>
